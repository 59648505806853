<template>
  <div>
    <el-card>
      <div>
        <section id="banner">
          <div class="inner">
            <h1>404</h1>
            <h3 class="pb-2">This is not the page you're looking for.</h3>
            <router-link :to="{ name: 'SATList' }">Homepage</router-link>
          </div>
        </section>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  metaInfo() {
    return {
      title: "404 - " + this.CompanyName
    };
  },

  mixins: [],

  props: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
::v-deep .el-card {
  box-shadow: none;
  border: none;
}

#banner {
  display: -ms-flexbox;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  padding: 2em 0 6em 0;
  -moz-align-items: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  min-height: 75vh;
  height: 75vh;
  position: relative;
  text-align: center;
  color: var(--themeColor);
}
</style>
